exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-apply-form-index-tsx": () => import("./../../../src/pages/apply-form/index.tsx" /* webpackChunkName: "component---src-pages-apply-form-index-tsx" */),
  "component---src-templates-about-us-template-tsx": () => import("./../../../src/templates/about-us-template.tsx" /* webpackChunkName: "component---src-templates-about-us-template-tsx" */),
  "component---src-templates-article-template-tsx": () => import("./../../../src/templates/article-template.tsx" /* webpackChunkName: "component---src-templates-article-template-tsx" */),
  "component---src-templates-career-template-tsx": () => import("./../../../src/templates/career-template.tsx" /* webpackChunkName: "component---src-templates-career-template-tsx" */),
  "component---src-templates-careers-template-tsx": () => import("./../../../src/templates/careers-template.tsx" /* webpackChunkName: "component---src-templates-careers-template-tsx" */),
  "component---src-templates-contact-us-page-template-tsx": () => import("./../../../src/templates/contact-us-page-template.tsx" /* webpackChunkName: "component---src-templates-contact-us-page-template-tsx" */),
  "component---src-templates-education-template-tsx": () => import("./../../../src/templates/education-template.tsx" /* webpackChunkName: "component---src-templates-education-template-tsx" */),
  "component---src-templates-home-template-tsx": () => import("./../../../src/templates/home-template.tsx" /* webpackChunkName: "component---src-templates-home-template-tsx" */),
  "component---src-templates-service-page-template-tsx": () => import("./../../../src/templates/service-page-template.tsx" /* webpackChunkName: "component---src-templates-service-page-template-tsx" */),
  "component---src-templates-services-template-tsx": () => import("./../../../src/templates/services-template.tsx" /* webpackChunkName: "component---src-templates-services-template-tsx" */)
}

